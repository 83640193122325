import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CustomDialog from 'components/customDialog'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import Page from 'layouts/Page'
import React, { useState, useContext, useEffect } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MAIL_BODY } from 'constants/constants';
import { postDataAsync } from 'services/httpRequest';
import FilePreview from 'components/FilePreviewer/FilePreviewer';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';

const MAX_COUNT = 5;

export default function EnvioDocumento() {

    const [previewPTC, setPreviewPTC] = useState(false);

    const { state } = useContext(CotizacionContext)
    const { solicitudSlice } = useSelector(state => state.solicitud);
    const { documentoTemp, propuesta, json } = state

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const history = useHistory();
    const listaMails = [{ id: 1, mail: "nicolas.giannici@snoopconsulting.com" }];
    // const listaMails = cep.emailsParaEnvio.split(",").map( function (mail, index) {
    //     return {id:index, mail: mail}
    // }
    // )
    // const listaMails = [
    //     {
    //         id:1,
    //         mail:"eduardo.yujra@snoopconsulting.com"
    //     },
    //     {
    //         id:2,
    //         mail:"nicolas.giannici@snoopconsulting.com"
    //     }
    // ]
    const bodyTemplate = MAIL_BODY

    const [edicion, setEdicion] = useState(false)

    const [body, setBody] = useState(bodyTemplate)

    const handleBody = (text) => {
        setBody(text)
    }

    const handleEnviarDocumento = (e) => {
        e.preventDefault()
        setOpenDialog(true)
    }

    //Dialog
    const [openDialog, setOpenDialog] = useState(false)
    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleEnviar = async (e) => {
        setLoading(true)

        var data = new FormData()


        value.forEach(contacto => {
            data.append("Destinatarios", contacto.mail)
        })
        mailCopia.forEach(contacto => {
            data.append("mailCopia", contacto.mail)
        })
        mailCopiaOculta.forEach(contacto => {
            data.append("mailCopiaOculta", contacto.mail)
        })

        data.append("texto", body)


        for (const file of uploadedFiles) {
            var base64 = file.name + "|" + await blobtoData(file);
            data.append('adjuntos', base64)
        }

        var base64Doc = "PTC - " + json.codigo + ".pdf|" + await blobtoData(documentoTemp)
        data.append("adjuntos", base64Doc)

        postDataAsync(
            `propuesta/${propuesta.id}/enviarDocumento`,
            data,
            { headers: { "Content-Type": "multipart/form-data" } }
        )
            .then((response) => {
                setLoading(false)
                setSuccess(true)
            })
            .catch((response => {
                setLoading(false)
                setError(true)
                console.error('error', response)
            }));


        handleCloseDialog()

    }

    //--------------blob----async
    function blobtoData(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
        })
    }

    //AUTOCOMPLETE DESTINATARIO
    const [value, setValue] = useState([]);
    const [inputValue, setInputValue] = useState('')
    const filter = createFilterOptions();
    const handleAutoComplete = (e, newValue) => {
        if (typeof newValue === 'string') {
            setValue({
                title: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                id: 0,
                mail: newValue.inputValue,
            });
        } else {

            setValue(newValue);
        }
    }
    const handleInput = (e, newInputValue) => {
        setInputValue(newInputValue)
    }
    //FIN

    //AUTOCOMPLETE COPIA
    const mailCopiaList = []
    const [mailCopia, setMailCopia] = useState([]);
    const [inputMailCopia, setInputMailCopia] = useState('')
    const handleAutoCompleteCopia = (e, newValue) => {
        if (typeof newValue === 'string') {
            setMailCopia({
                title: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setMailCopia({
                id: 0,
                mail: newValue.inputValue,
            });
        } else {
            setMailCopia(newValue);
        }
    }
    const handleInputCopia = (e, newInputValue) => {
        setInputMailCopia(newInputValue)
    }
    //FIN

    //AUTOCOMPLETE COPIA OCULTA
    const mailCopiaOcultaList = []
    const [mailCopiaOculta, setMailCopiaOculta] = useState([]);
    const [inputMailCopiaOculta, setInputMailCopiaOculta] = useState('')
    const handleAutoCompleteCopiaOculta = (e, newValue) => {
        if (typeof newValue === 'string') {
            setMailCopiaOculta({
                title: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setMailCopiaOculta({
                id: 0,
                mail: newValue.inputValue,
            });
        } else {
            setMailCopiaOculta(newValue);
        }
    }
    const handleInputCopiaOculta = (e, newInputValue) => {
        setInputMailCopiaOculta(newInputValue)
    }
    //FIN

    //ADJUNTOS
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false)
    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles]
        let limitExceeded = false
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file)
                if (uploaded.length === MAX_COUNT) setFileLimit(true)
                if (uploaded.length > MAX_COUNT) {
                    alert(`Solo se pueden subir un maximo de ${MAX_COUNT} archivos`);
                    setFileLimit(false)
                    limitExceeded = true;
                    return true
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
    }
    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles)
    }
    const handleRemoveFile = (name) => {
        var filterFiles = [...uploadedFiles]
        setUploadedFiles(filterFiles.filter(f => f.name !== name))
        if (filterFiles.length <= MAX_COUNT) {
            setFileLimit(false)
        }
    }
    //ADJUNTOS FIN

    const handleCancelar = () => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}/cotizacion/${state.json.id}/propuesta/${propuesta.id}`)
    }

    return (
        <Page>
            <Paper sx={{ marginTop: "20px", marginBottom: "20px", height: "50%" }}>
                <Box display="flex" sx={{ marginTop: "20px" }} flexDirection="column">

                    <Box display="flex" sx={{ width: "100%", marginTop: "10px" }}>
                        <Typography variant='h5' fontWeight="bold">Envio de Propuesta</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={2} sx={{ marginTop: "20px" }}>
                        <form onSubmit={handleEnviarDocumento} style={{ width: "100%" }}>
                            <Box display="flex" flexDirection="column" gap={2}>
                                {/* MAIL DESTINATARIOS */}
                                <Autocomplete
                                    autoHighlight
                                    disableClearable
                                    fullWidth
                                    noOptionsText="Sin resultados"
                                    value={value}
                                    disabled={loading}
                                    multiple
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue
                                        }
                                        return option.mail
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(e, newInputValue) => handleInput(e, newInputValue)}
                                    id="autocomplete-org-test"
                                    onChange={(e, newValue) => handleAutoComplete(e, newValue)}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.mail);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: 0,
                                                mail: inputValue,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    options={listaMails}
                                    getOptionDisabled={(option) => {
                                        option === option
                                    }}
                                    isOptionEqualToValue={(option, value) => option.mail === value.mail}
                                    renderInput={
                                        (params) => (
                                            <TextField
                                                required={value.length === 0}
                                                {...params}
                                                label="Destinatario"
                                            />
                                        )
                                    }
                                />

                                {/* MAIL COPIA */}
                                <Autocomplete
                                    autoHighlight
                                    disableClearable
                                    fullWidth
                                    disabled={loading}
                                    noOptionsText=""
                                    value={mailCopia}
                                    multiple
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue
                                        }
                                        return option.mail
                                    }}
                                    inputValue={inputMailCopia}
                                    onInputChange={(e, newInputValue) => handleInputCopia(e, newInputValue)}
                                    id="autocomplete-org-test"
                                    onChange={(e, newValue) => handleAutoCompleteCopia(e, newValue)}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.mail);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: 0,
                                                mail: inputValue,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    options={mailCopiaList}
                                    getOptionDisabled={(option) => {
                                        option === option
                                    }}
                                    isOptionEqualToValue={(option, value) => option.mail === value.mail}
                                    renderInput={
                                        (params) => (
                                            <TextField
                                                {...params}
                                                label="Copia"
                                            />
                                        )
                                    }
                                />
                                {/* MAIL COPIA OCULTA */}
                                <Autocomplete
                                    autoHighlight
                                    disableClearable
                                    fullWidth
                                    disabled={loading}
                                    noOptionsText=""
                                    value={mailCopiaOculta}
                                    multiple
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue
                                        }
                                        return option.mail
                                    }}
                                    inputValue={inputMailCopiaOculta}
                                    onInputChange={(e, newInputValue) => handleInputCopiaOculta(e, newInputValue)}
                                    id="autocomplete-org-test"
                                    onChange={(e, newValue) => handleAutoCompleteCopiaOculta(e, newValue)}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.mail);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: 0,
                                                mail: inputValue,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    options={mailCopiaOcultaList}
                                    getOptionDisabled={(option) => {
                                        option === option
                                    }}
                                    isOptionEqualToValue={(option, value) => option.mail === value.mail}
                                    renderInput={
                                        (params) => (
                                            <TextField
                                                {...params}
                                                label="Copia Oculta"
                                            />
                                        )
                                    }
                                />

                            </Box>
                            <Box display="flex" flexDirection="row" sx={{ marginTop: "20px", marginBottom: "20px" }} gap={2}>

                                <ReactQuill
                                    style={{ height: "50vh", borderRadius: "50px" }}
                                    value={body}
                                    onChange={handleBody}
                                    readOnly={edicion}
                                ></ReactQuill>

                            </Box>
                            <Box display="flex" flexDirection="column" gap={2}>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    {
                                        uploadedFiles.map(file => (
                                            <Box display="flex" sx={{ border: "1px solid black", borderRadius: "4px" }} justifyContent="center">
                                                <Typography sx={{ marginLeft: "5px" }} alignSelf="center" variant='body'>{file.name}</Typography>
                                                <IconButton onClick={() => handleRemoveFile(file.name)} disabled={loading}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                <Button sx={{ marginTop: "40px" }} variant="contained" component="label" disabled={fileLimit || loading} startIcon={<AttachFileIcon />}>Adjuntar archivos
                                    <input
                                        id="filesUpload"
                                        accept='application/pdf, image/png, image/jpeg'
                                        type="file"
                                        hidden
                                        multiple
                                        onChange={handleFileEvent}
                                        disabled={fileLimit}
                                    >

                                    </input>
                                </Button>
                            </Box>

                            <Box display="flex" justifyContent="center" sx={{ marginTop: "20px", marginBottom: "20px" }} gap={2}>
                                <Button variant='outlined' sx={{ width: "30%" }} onClick={() => setPreviewPTC(p => !p)} color='secondary' disabled={loading}>Previsualizar Propuesta</Button>
                                <Button variant='outlined' sx={{ width: "30%" }} onClick={handleCancelar} color='secondary' disabled={loading}>Cancelar</Button>
                                <Button variant='contained' sx={{ width: "30%" }} type="submit" disabled={loading}>
                                    {
                                        loading ?
                                            <CircularProgress />
                                            :
                                            <>Enviar</>
                                    }
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Paper>

            <FilePreview opened={previewPTC} onClose={() => setPreviewPTC(false)} file={{ fileName: "Propuesta", file: documentoTemp, extension: "application/pdf" }} />

            <CustomDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                title={"Enviando documento"}
                content={"El documento se va a enviar ¿esta seguro de enviarlo?"}
                handleAgree={() => handleEnviar()}
                onlyAgree={true}
            />
            <CustomDialog
                openDialog={success}
                handleCloseDialog={() => setSuccess(false)}
                title={"El Documento se envio correctamente"}
                content={"¿Desea volver a la propuesta?"}
                handleAgree={() => handleCancelar()}
                onlyAgree={true}
            />
        </Page>
    )
}
import { Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Page from 'layouts/Page'
import React, { useContext, useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ParrafoDocumento from 'components/ParrafoDocumento';
import { useSelector } from 'react-redux';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { _getDocumento } from 'services/Propuesta';
import { COLUMNAS, TIPO_ESTADO, TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import TablaConceptosDocumento from 'components/TablaConceptosDocumento';
import { getSecciones } from 'services/Documento';
import { setDocumentoTemp, setSeccionesDocumento, setSeccionesEdit } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { dateToString } from 'utlis/stringUtils';
import ResumenSolicitud from 'components/ResumenSolicitud';
import ResumenCotizacion from 'components/ResumenCotizacion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { postDataAsync } from 'services/httpRequest';

function Documento() {

  const { state, dispatch } = useContext(CotizacionContext)
  const solicitudSlice = useSelector(state => state.solicitud)
  const { tipo, cep } = solicitudSlice
  const { propuesta, json: cotizacion, secciones, seccionesEdit } = state
  const history = useHistory();

  useEffect(() => {
    getSecciones(propuesta.id)
      .then((response) => {
        let seccionesFix = response.filter(s => s.tipo === tipo).map(function (seccion) {
          return { ...seccion, modificada: false, visibilidad: seccion.titulo === "ACLARACIONES" ? false : true }
        })

        if (tipo === TIPO_SOLICITUD_ALIMENTOS) {
          let normaMenorJerarquia = null;

          cotizacion.normas.forEach(objeto => {
            const norma = objeto.norma;
            if (!normaMenorJerarquia || norma.jerarquiaAlimentos < normaMenorJerarquia.jerarquiaAlimentos) {
              normaMenorJerarquia = norma;
            }
          });

          seccionesFix = seccionesFix.filter(s => s.tipoNorma === normaMenorJerarquia.jerarquiaAlimentos || s.tipoNorma === 0)
        }
        dispatch(setSeccionesDocumento(seccionesFix))
        dispatch(setSeccionesEdit(seccionesFix))
      })
      .catch((error) => {
        console.error("ERROR GET SECCIONES DOCUMENTO", error)
      })
  }, [])

  var columnasINIT = [
    {
      nombre: COLUMNAS.DIAS_IRAM,
      visible: true
    },
    {
      nombre: COLUMNAS.DIAS_ORGANIZACION,
      visible: true
    },
    {
      nombre: COLUMNAS.ARANCEL,
      visible: true
    },
    {
      nombre: COLUMNAS.PERIODO,
      visible: true
    },
    {
      nombre: COLUMNAS.BONIFICACION,
      visible: true
    },
    {
      nombre: COLUMNAS.RECARGO,
      visible: true
    },
  ]

  const [visibilidad, setVisivilidad] = useState(columnasINIT)

  const handleVisibilidad = (index, columna) => {
    let listaAux = [...visibilidad]
    listaAux[index]["visible"] = !visibilidad[index]["visible"]
    setVisivilidad(listaAux)
  }

  const [loading, setLoading] = React.useState(false);

  const renderCaption = () => {
    var indiceComentario = 1

    var conceptosFix = propuesta.conceptos.map(function (concepto, index) {
      var aux = null;
      if (concepto.comentario) {
        aux = { ...concepto, indice: indiceComentario }
        indiceComentario++
      } else {
        aux = { ...concepto }
      }
      return aux;
    })
    return (
      <>
        {
          conceptosFix.map((item, index) => {
            if (item.comentario) {
              return (<Typography variant="caption">({item.indice}) {item.comentario}</Typography>)
            }
          }
          )
        }
      </>
    )
  }

  const getDocumento = () => {
    setLoading(true)
    var data = {
      propuestaId: propuesta.id,
      data: {
        columnas: visibilidad,
        secciones: seccionesEdit
      }
    }
    // descargarDocumento(data)

    postDataAsync(`propuesta/${propuesta.id}/descargarDocumento`, data.data, { responseType: 'blob' })
      .then((response) => {
        setLoading(false)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((response => {
        setLoading(false)
        console.error('error', response)
      }));
  }

  const onEnviarDocumento = () => {
    setLoading(true)
    var data = {
      propuestaId: propuesta.id,
      data: {
        columnas: visibilidad,
        secciones: seccionesEdit
      }
    }
    // enviarDocumento(data)

    postDataAsync(`propuesta/${propuesta.id}/descargarDocumento`, data.data, { responseType: 'blob' })
      .then((response) => {
        setLoading(false)
        dispatch(setDocumentoTemp(response.data))
        history.push("/enviodocumento");
      })
      .catch((response => {
        setLoading(false)
        console.error('error', response)
      }));
  }

  const desactivado = () => {
    if (loading) {
      return true;
    } else {
      return propuesta.estado.estado === TIPO_ESTADO.ENVIADA
    }
  }

  const getConceptosFilter = () => {
    var filter = propuesta.conceptos.filter(c => c.habilitado === true || c.comentario !== null || c.realizada || c.aCotizar)
    return filter;
  }


  return (
    <Page>
      <Paper className="paperSolicitud" sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <ResumenSolicitud solicitud={solicitudSlice} cotizacion={cotizacion} />
      </Paper>
      <Paper className="paperCotizacion" sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <ResumenCotizacion cotizacion={cotizacion} />
      </Paper>
      <Paper className="paperDocumento" sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Box display="flex">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography fontWeight="bold">Conceptos</Typography></TableCell>
                  {cotizacion && cotizacion.tipo !== TIPO_SOLICITUD_PRODUCTO && <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">Días IRAM</Typography>
                      <IconButton onClick={() => handleVisibilidad(0, COLUMNAS.DIAS_IRAM)} disabled={desactivado()}>
                        {
                          visibilidad[0].visible ?
                            <VisibilityIcon />
                            :
                            <VisibilityOffIcon />
                        }
                      </IconButton>
                    </Box>
                  </TableCell>}
                  {cotizacion && cotizacion.tipo !== TIPO_SOLICITUD_PRODUCTO && <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">Días Organizacion</Typography>
                      <IconButton onClick={() => handleVisibilidad(1, COLUMNAS.DIAS_ORGANIZACION)} disabled={desactivado()}>
                        {
                          visibilidad[1].visible ?
                            <VisibilityIcon />
                            :
                            <VisibilityOffIcon />
                        }
                      </IconButton>
                    </Box>
                  </TableCell>}
                  <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">Arancel Unitario</Typography>
                      <IconButton onClick={() => handleVisibilidad(2, COLUMNAS.ARANCEL)} disabled={desactivado()}>
                        {
                          visibilidad[2].visible ?
                            <VisibilityIcon />
                            :
                            <VisibilityOffIcon />
                        }
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">Período</Typography>
                      <IconButton onClick={() => handleVisibilidad(3, COLUMNAS.PERIODO)} disabled={desactivado()}>
                        {
                          visibilidad[3].visible ?
                            <VisibilityIcon />
                            :
                            <VisibilityOffIcon />
                        }
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">Bonificación</Typography>
                      <IconButton onClick={() => handleVisibilidad(4, COLUMNAS.BONIFICACION)} disabled={desactivado()}>
                        {
                          visibilidad[4].visible ?
                            <VisibilityIcon />
                            :
                            <VisibilityOffIcon />
                        }
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">Recargo</Typography>
                      <IconButton disabled>
                        <VisibilityOffIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Typography alignSelf="center" fontWeight="bold">TOTAL</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TablaConceptosDocumento conceptos={getConceptosFilter()} />
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
        <Box display="flex" flexDirection="column">
          {
            renderCaption()
          }
        </Box>
        <Box display="flex" className="sectorDocumento" flexDirection="column">
          {
            secciones.length === 0 ?
              <div>Cargando...</div>
              :
              <>
                <>
                  {
                    secciones.map((seccion, index) => <ParrafoDocumento key={"parrafo" + index} seccion={seccion} index={index} desactivado={desactivado()} />)
                  }
                  {
                    cep &&
                    <ParrafoDocumento key={"parrafoalcance"} seccion={{ id: 0, texto: cep?.alcance, titulo: "Alcance del Sistema", orden: 0, visibilidad: true, modificada: false }} desactivado={true} />
                  }

                </>
              </>

          }
        </Box>
      </Paper>
      <Box display="flex" gap={2}>
        <Button onClick={getDocumento} variant='contained' fullWidth disabled={loading}>
          {
            loading ?
              <div><CircularProgress /></div>
              :
              <div>Descargar Documento</div>
          }

        </Button>
        {/* <Button onClick={onEnviarDocumento} variant="outlined" fullWidth disabled={desactivado()}> */}
        <Button onClick={onEnviarDocumento} variant="outlined" fullWidth >
          {
            propuesta.estado.estado === TIPO_ESTADO.ENVIADA ?
              <>
                Documento enviado - {dateToString(propuesta.fechaEnviada)}
              </>
              :
              <>
                Enviar Documento

              </>
          }
        </Button>
      </Box>
    </Page>
  )
}

export default Documento